import { useTranslation } from "react-i18next";
import { useUser } from "@/providers/useUser";
import {
  UserRole,
  BootcampBannerEntry,
  ValidateBootcampEnrrollments,
} from "@/schemaTypes";
import { resultingDate } from "@/utils/dateTimeFormat/dateTimeFormats";
import { DataProps } from "../Modal/utils";
import { useEnrollmentMutations } from "./useEnrollmentMutations";
import { useEnrollmentValidations } from "./useEnrollmentValidations";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { useToastMessage } from "./toastUtils";

export const useEnrollmentUtils = () => {
  const { t } = useTranslation();
  const { hasRoles, user } = useUser();
  const isPremium = hasRoles([UserRole.PremiumSubscriber]);
  const { enrollUserMutation, unblockUserMutation, blockUserMutation } =
    useEnrollmentMutations();
  const { validateUserForEnrollment, checkUserEnrollmentStatus } =
    useEnrollmentValidations();
  const { showSuccessToast } = useToastMessage();

  const fetchValidateEnrollment = async (
    bootcamp: BootcampBannerEntry,
    userId: string,
    setModalProps: (props: DataProps | undefined) => void,
    onOpen: () => void
  ) => {
    try {
      const { data } = await validateUserForEnrollment({
        variables: {
          validateUserEnrollmentInput: {
            bootcampReleaseId: bootcamp.release?.id,
            userId,
          },
        },
      });

      const validationType =
        data?.validateUserForEnrollment?.type ??
        ValidateBootcampEnrrollments.Success;

      const modalProps: DataProps = {
        bootcamp,
        type: validationType,
      };

      switch (validationType) {
        case ValidateBootcampEnrrollments.Crosseddates:
          modalProps.message = t("Crossdates Message");
          break;
        case ValidateBootcampEnrrollments.OutdaysBefore:
          modalProps.title = t("Register closed");
          modalProps.message = t("Days remaining", {
            resultingDate: resultingDate(
              bootcamp.release?.startDate?.toString()
            ),
          });
          break;
        case ValidateBootcampEnrrollments.OutdaysAfter:
          modalProps.title = t("Register block");
          modalProps.message = t("Register closed message");
          break;
        case ValidateBootcampEnrrollments.Success:
          modalProps.title = t("Register message");
          break;
        default:
          modalProps.title = "Error";
          modalProps.message = t("Unknown validation type");
          break;
      }

      setModalProps(modalProps);
      onOpen();
    } catch (validateEnrollmentError) {
      SBErrorPubSub.publish({
        component: "useEnrollmentUtils.tsx line 78",
        message:
          (validateEnrollmentError as Error)?.message ||
          "Error validating user enrollment",
        showInProd: true,
      });
    }
  };

  const fetchUserEnrollmentStatus = async (
    bootcampReleaseId: string,
    userId: string
  ): Promise<boolean> => {
    try {
      const { data } = await checkUserEnrollmentStatus({
        variables: {
          validateUserEnrollmentInput: {
            bootcampReleaseId,
            userId,
          },
        },
      });

      const isRegistered = data?.validateUserEnrollment ?? false;
      return isRegistered;
    } catch (enrollmentStatusError) {
      SBErrorPubSub.publish({
        component: "useEnrollmentUtils.tsx",
        message:
          (enrollmentStatusError as Error)?.message ||
          "Error fetching user enrollment status",
        showInProd: true,
      });
    }
    return false;
  };

  const enrollUserInBC = async (
    bootcamp: BootcampBannerEntry,
    userId: string,
    successMessage: string,
    handleReset: () => void
  ) => {
    try {
      const responseData = await enrollUserMutation({
        variables: {
          enrollUserMultipleInput: {
            cohortIds: [bootcamp.cohort?.id ?? ""],
            roleInCohort: UserRole.Student,
            userId,
            totalAttendees: bootcamp.totalAttendees,
            bootcampReleaseId: bootcamp.release?.id,
            shouldNotifySalesforce: true,
          },
        },
      });
      if (responseData) {
        handleReset();
        showSuccessToast(successMessage);
      }
    } catch (enrollError) {
      SBErrorPubSub.publish({
        component: "useEnrollmentUtils.tsx line 110",
        message:
          (enrollError as Error)?.message || "Error enrolling user in Bootcamp",
        showInProd: true,
      });
    }
  };

  const blockBcUserEnroll = async (
    bootcamp: BootcampBannerEntry,
    userId: string,
    setModalProps: (props: DataProps | undefined) => void,
    onOpen: () => void
  ) => {
    try {
      const responseData = await blockUserMutation({
        variables: {
          enrollUserMultipleInput: {
            cohortIds: [bootcamp.cohort?.id ?? ""],
            roleInCohort: UserRole.Student,
            userId,
            shouldNotifySalesforce: true,
          },
        },
      });

      if (responseData) {
        setModalProps({
          title: t("Your attendance has been canceled"),
          message: t(
            "If you change your mind, you can always register again. We hope to see you at future events!"
          ),
          bootcamp,
          type: ValidateBootcampEnrrollments.Confirmblockenroll,
        });
        onOpen();
      }
    } catch (blockEnrollError) {
      SBErrorPubSub.publish({
        component: "useEnrollmentUtils.tsx line 149",
        message:
          (blockEnrollError as Error)?.message ||
          "Error blocking user in Bootcamp",
        showInProd: true,
      });
    }
  };

  const unblockBcUserEnroll = async (
    bootcamp: BootcampBannerEntry,
    userId: string,
    successMessage: string,
    handleReset: () => void
  ) => {
    try {
      const responseData = await unblockUserMutation({
        variables: {
          enrollUserMultipleInput: {
            cohortIds: [bootcamp.cohort?.id ?? ""],
            roleInCohort: UserRole.Student,
            userId,
            totalAttendees: bootcamp.totalAttendees,
            bootcampReleaseId: bootcamp.release?.id,
            shouldNotifySalesforce: true,
          },
        },
      });
      if (responseData) {
        handleReset();
        showSuccessToast(successMessage);
      }
    } catch (unblockEnrollError) {
      SBErrorPubSub.publish({
        component: "useEnrollmentUtils.tsx line 181",
        message:
          (unblockEnrollError as Error)?.message ||
          "Error unblocking user in Bootcamp",
        showInProd: true,
      });
    }
  };

  const handleEnrollment = (
    bootcamp: BootcampBannerEntry,
    setModalProps: (props: DataProps | undefined) => void,
    onOpen: () => void
  ) => {
    if (isPremium) {
      fetchValidateEnrollment(bootcamp, user?.id ?? "", setModalProps, onOpen);
    } else {
      window.open(bootcamp.release?.signUpLink ?? "");
    }
  };

  const handleBlockBcUserEnroll = (
    bootcamp: BootcampBannerEntry,
    setModalProps: (props: DataProps | undefined) => void,
    onOpen: () => void
  ) => {
    setModalProps({
      title: t(
        "Are you sure you want to cancel your participation in this Bootcamp?"
      ),
      bootcamp: bootcamp,
      type: ValidateBootcampEnrrollments.Blockenroll,
    });
    onOpen();
  };

  return {
    fetchValidateEnrollment,
    enrollUserInBC,
    unblockBcUserEnroll,
    blockBcUserEnroll,
    handleEnrollment,
    fetchUserEnrollmentStatus,
    handleBlockBcUserEnroll,
  };
};
