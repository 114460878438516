function validateComponentVisibility(createdAt: string) {
  const creationDate = new Date(createdAt);

  const startDate = new Date(creationDate);
  startDate.setDate(startDate.getDate() + 3);

  const endDate = new Date(creationDate);
  endDate.setDate(endDate.getDate() + 8);

  const currentDate = new Date();
  return currentDate >= startDate && currentDate <= endDate;
}
export default validateComponentVisibility;
