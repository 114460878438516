import mainLogo from "/img/logo_smartbeemo.svg";
import beemo from "/img/beemo.svg";
import {
  Accordion,
  Box,
  calc,
  Flex,
  Img,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Ico } from "@/assets/icons";
import IconButton from "@/components/Button/Icon";
import { AppRoute } from "@/AppRoute";
import AcademyTabs from "./academyTabs";
import NavigationTooltipButton from "./navigationTooltipButton";
import NavigationPopoverButton from "./navigationPopoverButton";
import { useNavigate } from "react-router-dom";
import CommunityTabs from "./communityTabs";
// import ExpertsTabs from "./expertsTabs";
import ProductsTabs from "./productsTabs";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";
import { HighTicketNavigationWidget } from "./HighTicket";

const NewSideMenu = ({ show = false }) => {
  const { hasRoles } = useUser();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const navigate = useNavigate();
  const [isMenuExpanded, setIsMenuExpanded] = useState(true);
  const [expandedItems, setExpandedItems] = useState<number[]>([]);

  const isPremiumOrPro = hasRoles([
    UserRole.Pro,
    UserRole.Admin,
    UserRole.PremiumSubscriber,
  ]);

  const menuExpandIcon = isMenuExpanded ? (
    <Ico.ChevronLeft fill="#1E1E23" />
  ) : (
    <Ico.ChevronRight fill="#1E1E23" />
  );

  const handleSetMenuExpandedDelayed = () => {
    if (!isMobile) {
      setTimeout(() => {
        setIsMenuExpanded((val) => !val);
      }, 300);
    }
  };

  const handleAccordionChange = (expandedIndex: number[]) => {
    setExpandedItems(expandedIndex);
  };

  useEffect(() => {
    if (!isMobile) {
      setIsMenuExpanded(true);
      setExpandedItems([]);
    }
  }, [isMobile]);

  return show ? (
    <Flex
      flexDirection={"column"}
      justifyContent={"space-between"}
      w={isMenuExpanded ? (isMobile ? "314px" : "248px") : "64px"}
      h={"100vh"}
      px={isMobile ? "8px" : "16px"}
      py={"24px"}
      bg={"#fff"}
      borderRight={"1px solid"}
      borderColor={"neutral.100"}
      flexDir={"column"}
      alignItems={isMenuExpanded ? "flex-start" : "center"}
      transition={"all 0.5s ease"}
      position={isMobile ? "fixed" : "sticky"}
      top={isMobile ? "60px" : 0}
      zIndex={1000}
      mt={isMobile ? "0" : "-60px"}
    >
      <Flex
        w={"100%"}
        h={isMobile ? "100%" : calc("100% - 220px").toString()}
        flexDirection={"column"}
      >
        <Box
          cursor={"pointer"}
          onClick={() => navigate(AppRoute.Base)}
          display={isMobile ? "none" : "auto"}
        >
          <Img
            src={beemo}
            alt="beemo"
            minH={"32px"}
            maxH={"32px"}
            mb={"48px"}
            display={isMenuExpanded ? "none" : "block"}
          />
          <Img
            src={mainLogo}
            alt="logo"
            minH={"32px"}
            maxH={"32px"}
            mb={"48px"}
            display={isMenuExpanded ? "block" : "none"}
          />
        </Box>

        {!isMobile && (
          <IconButton
            as={Flex}
            cursor={"pointer"}
            icon={menuExpandIcon}
            onClick={handleSetMenuExpandedDelayed}
            ariaLabel="Expandir"
            variant="primary"
            bg={"#fff"}
            borderRadius={"50%"}
            border={"3px solid"}
            borderColor={"neutral.50"}
            boxShadow="-1px -1px 10px 0px rgba(100, 100, 100, 0.16)"
            position={"absolute"}
            top={"56px"}
            right={isMenuExpanded ? "-20px" : "-20px"}
            _hover={{ bg: "auto" }}
            _active={{
              bg: "neutral.100",
              boxShadow: "-1px -1px 10px 0px rgba(100, 100, 100, 0.16)",
            }}
          />
        )}

        <Accordion
          as={Flex}
          w={"100%"}
          gap={"4px"}
          flexDir={"column"}
          allowMultiple={isMobile}
          allowToggle={!isMobile}
          index={isMobile ? [0, 1, 2, 3, 4] : expandedItems}
          onChange={handleAccordionChange}
        >
          <NavigationPopoverButton
            title="Academia"
            baseRoutes={[AppRoute.Learn, AppRoute.Base]}
            isMenuExpanded={isMenuExpanded}
            icon={<Ico.MonitorRecorder />}
            CustomPopoverContent={
              <AcademyTabs showPaddingLeft={false} darkHover />
            }
            AccordionContent={<AcademyTabs />}
            onClick={() => navigate(AppRoute.Learn)}
          />

          <NavigationPopoverButton
            title="Comunidad"
            baseRoutes={[AppRoute.Community, AppRoute.Forum]}
            isMenuExpanded={isMenuExpanded}
            icon={<Ico.CommunicationText2 />}
            CustomPopoverContent={
              <CommunityTabs showPaddingLeft={false} darkHover />
            }
            AccordionContent={<CommunityTabs />}
            onClick={() => navigate(AppRoute.Forum)}
          />

          <NavigationTooltipButton
            label="Mentores"
            icon={<Ico.People />}
            route={AppRoute.Consulting}
            isMenuExpanded={isMenuExpanded}
          />

          {/* <NavigationTooltipButton
          label="Agencias"
          icon={<Ico.People />}
          route={AppRoute.ServicesMarketplace}
          isMenuExpanded={isMenuExpanded}
        /> */}

          <NavigationPopoverButton
            baseRoutes={[AppRoute.Products, AppRoute.ChinaAgent]}
            title="Productos"
            isMenuExpanded={isMenuExpanded}
            icon={<Ico.BoxMagnifyingGlass />}
            CustomPopoverContent={
              <ProductsTabs showPaddingLeft={false} darkHover />
            }
            AccordionContent={<ProductsTabs validateRole={isPremiumOrPro} />}
            onClick={() => navigate(AppRoute.Products)}
          />

          <NavigationTooltipButton
            label={"Beneficios"}
            route={AppRoute.Solutions}
            isMenuExpanded={isMenuExpanded}
            icon={<Ico.Gift />}
          />
        </Accordion>
      </Flex>
      {!isMobile && (
        <HighTicketNavigationWidget
          isMenuExpanded={isMenuExpanded}
        ></HighTicketNavigationWidget>
      )}
    </Flex>
  ) : null;
};

export default NewSideMenu;
