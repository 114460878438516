import { Box, Flex, Text } from "@chakra-ui/react";
import { Trans } from "react-i18next";
import { useEffect, useState } from "react";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminRoute } from "@/AppRoute";

interface NavItemProps {
  title: React.ReactNode;
  isActive: boolean;
  onClick: () => void;
}

const NavItem: React.FC<NavItemProps> = ({ title, isActive, onClick }) => (
  <Box
    onClick={onClick}
    cursor="pointer"
    p={2}
    mx={2}
    borderBottom={isActive ? "2px solid #109B9D" : "none"}
    _hover={{ bg: "gray.100" }}
  >
    <Text fontSize="lg">{title}</Text>
  </Box>
);

const AdministrationPanel: React.FC = () => {
  const navigate = useNavigate();
  const { hasRoles } = useUser();
  const isAdmin = hasRoles([UserRole.Admin]);
  const isEditor = hasRoles([UserRole.Editor]);
  const isAcademic = hasRoles([UserRole.Academic]);
  const isCommunity = hasRoles([UserRole.Community]);
  const isSolutions = hasRoles([UserRole.Solutions]);
  const isProducts = hasRoles([UserRole.Products]);
  const isConsultant = hasRoles([UserRole.Consultants]);
  const isMarketplace = hasRoles([UserRole.Marketplace]);
  const location = useLocation();
  const initialTab = (() => {
    const pathAfterAcademia = location.pathname.split(
      "/panel-de-administracion/"
    )[1];
    return pathAfterAcademia ? pathAfterAcademia.split("/")[0] : "general";
  })();

  const [selectedTab, setSelectedTab] = useState<string>("");

  const handleNavigation = (route: string) => {
    navigate(route);
  };

  useEffect(() => {
    setSelectedTab(initialTab);
  }, [initialTab]);

  return (
    <Box my={2}>
      <Flex direction="row" bg="none">
        {(isAdmin || isEditor) && (
          <NavItem
            title={<Trans>General</Trans>}
            isActive={selectedTab === "general"}
            onClick={() =>
              handleNavigation(
                `${AdminRoute.AdministrationPanel}${AdminRoute.General}`
              )
            }
          />
        )}
        {(isAdmin || (isEditor && isAcademic)) && (
          <NavItem
            title={<Trans>Academy</Trans>}
            isActive={selectedTab === "academia"}
            onClick={() =>
              handleNavigation(
                `${AdminRoute.AdministrationPanel}${AdminRoute.Academy}${AdminRoute.LiveSessions}`
              )
            }
          />
        )}
        {(isAdmin || (isEditor && isCommunity)) && (
          <NavItem
            title={<Trans>Community</Trans>}
            isActive={selectedTab === "comunidad"}
            onClick={() =>
              handleNavigation(
                `${AdminRoute.AdministrationPanel}${AdminRoute.Community}`
              )
            }
          />
        )}
        {(isAdmin || (isEditor && isSolutions)) && (
          <NavItem
            title={<Trans>Solutions</Trans>}
            isActive={selectedTab === "soluciones"}
            onClick={() =>
              handleNavigation(
                `${AdminRoute.AdministrationPanel}${AdminRoute.Solutions}`
              )
            }
          />
        )}
        {(isAdmin || (isEditor && isProducts)) && (
          <NavItem
            title={<Trans>Products</Trans>}
            isActive={selectedTab === "productos"}
            onClick={() =>
              handleNavigation(
                `${AdminRoute.AdministrationPanel}${AdminRoute.Products}`
              )
            }
          />
        )}
        {(isAdmin || (isEditor && isConsultant)) && (
          <NavItem
            title={<Trans>Consultores</Trans>}
            isActive={selectedTab === "consultores"}
            onClick={() =>
              handleNavigation(
                `${AdminRoute.AdministrationPanel}${AdminRoute.Consultants}`
              )
            }
          />
        )}
        {(isAdmin || (isEditor && isMarketplace)) && (
          <NavItem
            title={<Trans>Marketplace Servicios</Trans>}
            isActive={selectedTab === "marketplace"}
            onClick={() =>
              handleNavigation(
                `${AdminRoute.AdministrationPanel}${AdminRoute.Marketplace}`
              )
            }
          />
        )}
        {(isAdmin || isEditor) && (
          <NavItem
            title={<Trans>Mentorias High Ticket</Trans>}
            isActive={selectedTab === "mentorias"}
            onClick={() =>
              handleNavigation(
                `${AdminRoute.AdministrationPanel}${AdminRoute.HighTicketMentorship}`
              )
            }
          />
        )}
      </Flex>
    </Box>
  );
};

export default AdministrationPanel;
